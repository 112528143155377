<template>
    <div>
      <v-card class="rounded-lg" elevation="0">
        <v-tabs fixed-tabs background-color="green" dark>
          <v-tabs-slider color="#086318"></v-tabs-slider>
            <v-tab>RELATÓRIO DE CAPTAÇÃO</v-tab>
 
            <v-tab-item>
            
             <div>
              <div id="print-sticker">
                    <table style="width: 100%; font-family: Arial, Helvetica, sans-serif;" >
                        <tr>
                            <th style=" border: 2px solid #000; padding: 4px; width: 30%;">
                                <img src="./../../../../assets/logo.jpeg" style="width: 200px;">
                            </th>
                            <th style=" border: 2px solid #000; padding: 4px">
                                <p style="padding: 0px; font-size: 24px; margin: 0;">SISTransplantes</p>
                                <p style="padding: 0px; font-size: 16px; margin: 0;">Relatório de Captação ME</p>  
                            </th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Paciente Doador: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.donor.nome }}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Mãe do Doador: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.donor.mother_name }}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">CPF: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.donor.cpf }}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Data de Início da cirugia:</th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.donor.start_surgery }}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-siz: 14px">Clampeamento da aorta: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.donor.aortic_clamping }}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-siz: 14px">Tempo de Perfusão: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.donor.perfusion_time }}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Data de Fim da cirúrgia: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.donor.end_surgery }}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Motivo da não captação: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.donor.reason_for_not_takingup }}</th>
                        </tr>
                        <tr>
                            <th style=" border: 1px solid #000; padding: 4px font-weight: bold; font-size: 14px">Descrição Cirúrgica: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px">{{ this.donor.surgery_description }}</th>
                        </tr>
                    </table>
                    <table style="width: 100%; font-family: Arial, Helvetica, sans-serif;">
                        <tr>
                            <th style=" border: 2px solid #000; padding: 4px; width: 8%;" colspan="4">
                                <p style="padding: 0px; font-size: 18px; margin: 0;">Equipe de Captação</p> 
                            </th>
                        </tr>
                        <tr v-for="(captation, indice) in captations" :key="indice" colspan="2">
                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 8%;">Nome: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ captation.name }}</th>

                            <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 8%;">Registro: </th>
                            <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ captation.register }}</th>
                        </tr>
                        <tr v-for="(captation, indice) in captations" :key="indice">
                            
                        </tr>
                    </table>
                    <table style="width: 100%; font-family: Arial, Helvetica, sans-serif;">
                        <tr>
                            <th style=" border: 2px solid #000; padding: 4px; width: 1.5%;" colspan="6">
                                <p style="padding: 0px; font-size: 18px; margin: 0;">Orgãos Captados</p> 
                            </th>
                        </tr>
                        <tr v-for="(orgao, indice) in orgaos" :key="indice">
                              <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Orgão: </th>
                              <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.orgon_name }}</th>

                              <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 0.5%;">Centro Receptor: </th>
                              <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.receiver_center }}</th>

                              <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Devidamente Condicionado: </th>
                              <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.packed }}</th>
                         
                              <!-- <td style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Tronco Pulmonar: </td>
                              <td style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.lung_trunk }}</td> -->
                          </tr>
                    </table>
                    <table style="width: 100%; font-family: Arial, Helvetica, sans-serif;">
                        <tr>
                            <th style=" border: 2px solid #000; padding: 4px; width: 1.5%;" colspan="8">
                                <p style="padding: 0px; font-size: 18px; margin: 0;">Soluções de Preservação</p> 
                            </th>
                        </tr>
                        <tr v-for="(orgao, indice) in orgaos" :key="indice">
                              <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Orgão: </th>
                              <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.orgon_name }}</th>

                              <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1%;">Solução de Preservação: </th>
                              <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.preservation_solutions_name }}</th>

                              <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Quantidade: </th>
                              <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.quantity }}</th>

                              <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Lote: </th>
                              <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.lot }}</th>
                          
                              <!-- <td style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Tronco Pulmonar: </td>
                              <td style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.lung_trunk }}</td> -->
                          </tr>
                    </table>
                    <table style="width: 100%; font-family: Arial, Helvetica, sans-serif;">
                        <tr>
                            <th style=" border: 2px solid #000; padding: 4px; width: 1.5%;" colspan="8">
                                <p style="padding: 0px; font-size: 18px; margin: 0;">Nefrectomia</p> 
                            </th>
                        </tr>
                        <tr v-for="(orgao, indice) in orgaos" :key="indice">
                              <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Orgão: </th>
                              <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.orgon_name }}</th>

                              <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1%;">Solução de Preservação: </th>
                              <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.preservation_solutions_name }}</th>

                              <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Quantidade: </th>
                              <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.quantity }}</th>

                              <th style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Lote: </th>
                              <th style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.lot }}</th>
                          
                              <!-- <td style=" border: 1px solid #000; padding: 4px ;font-weight: bold; font-size: 14px; width: 1.5%;">Tronco Pulmonar: </td>
                              <td style=" border: 1px solid #000; padding: 4px; font-size: 12px;">{{ orgao.lung_trunk }}</td> -->
                          </tr>
                    </table>
                    <div style="padding: 38px"></div>
                    <hr style="border: 1px solid #000; width:50%;">
                    <p style="text-align: center; padding: 0px; margin: 0;">Assinatura</p>
                </div>
             </div>
             <br>
              <div class="buttons">
                <v-row>
                    <v-btn class="rounded-lg" elevation="0" type="submit" color="green">
                        <span class="textBtn" @click="$router.push('/captacao')">Sair</span>
                    </v-btn><p class="space-personalized"></p>
                    <v-btn class="rounded-lg" elevation="0" color="blue" @click="print()">
                        <v-icon dark color="white">mdi-printer</v-icon>
                    </v-btn>
                </v-row>
              </div>
             
            </v-tab-item>
      </v-tabs>
  </v-card>
  </div>
  </template>
  
  <script>
  import axios from "@/axios";
  
  export default {
    data() {
      return {
        headers: [
          {
            text: "Orgão",
            align: "center",
            value: "orgon_name",
          },
          { text: "Ações", value: "actions", sortable: false,  align: "center" },
        ],
        dialog: false,
        displayError: {
          display: false,
          message: [],
        },
        donor: [],
        captations: [],
        orgaos: [],
        serologyForm:{
            name: ""
        },
        name:"",
        captationId: "",
        numberOfPages: 0,
        loading: true,
        options: {},
        donorId: {},
        sortBy: "name",
        message: "",
        snackbar: false,
        loadingBTN: false,
        exibirMensagem: false,
        messageError: "",
      };
    },
    
    components: {
    },

    created() {
     
        this.donorId = { ...this.$route.query.item.donor }
        const id = this.donorId.id
          axios.get(`/captation/allByDonors/${id}`).then((response) => {
              this.donor = response.data.data[0];
              this.captationId = this.donor.id
                  axios.get(`/captations_team/${this.captationId}`).then((response) => {
                    this.captations = response.data.data;
                  });
          });
          axios.get(`/orgons_captured/${id}`).then((response) => {
            this.orgaos = response.data.data;
          });
    },

    watch: {
      sortBy: {
        handler() {
  
        }
      },
      options: {
        handler() {
          this.indoDataTable();
        },
      },
      deep: true,
    },
  
    methods: {
  
        aqui(){
          var tableBody = document.querySelector("#reportTable tbody");

              this.dataData.forEach(function(item) {
              var row = tableBody.insertRow();
              
              // Cria as células da linha com os valores do objeto
              var cellNome = row.insertCell();
              cellNome.textContent = item.nome;
              
              var cellIdade = row.insertCell();
              cellIdade.textContent = item.idade;
              
              var cellCidade = row.insertCell();
              cellCidade.textContent = item.cidade;
            });

        },

        print() {
            this.$htmlToPaper("print-sticker")
        },


        fillTable(){
            var tableBody = document.querySelector("#dataTable tbody")
              this.captations.forEach(function(item){
                var row = tableBody.insertRow();

                var cellOrgon = row.insertCell();
                cellOrgon.textContent = item.orgon_name
                
              })
        },

        indoDataTable() {
            this.loading = true;
        },

        mounted() {
            this.indoDataTable();
        },

        
    }

  };
  </script>
  
  <style scoped>

.buttons {
  padding: 12px;
}
  .header-table {
    display: flex;
    margin-bottom: 20px;
  }

  .d-flex{
    padding: 0 12px;
  }
  
  .space {
    width: 5px;
  }
  .space-personalized{
  padding: 2px;
  }

  .spacediferent {
  width: 2px;
}
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
  }
  .textBtn {
    font-family: "Poppins";
    color: #ffffff;
  }
  .create-user-dialog {
    padding: 20px;
  }
  .card {
    padding: 35px;
  }
  .banner {
    background: #b3ffab; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #27ae60,
      #1abc9c
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #ee106d,
      #e46987
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    border-radius: 8px;
  
    margin-bottom: 20px;
  
    display: flex;
    align-items: center;
  }
  .banner > h4 {
    color: #ffffff;
    padding: 10px;
  }
  .cardFooter {
    padding: 0 !important;
  }
  </style>
  